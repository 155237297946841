export enum ACTIONS {
  LOAD_COUNTRIES = "LOAD_COUNTRIES",
  LOAD_PROFILE = "LOAD_PROFILE",
  LOAD_RESTRICTIONS = "LOAD_RESTRICTIONS",
  CHECK_FILTERS = "CHECK_FILTERS",
  SEARCH = "SEARCH",
  RESET_SANCTIONS_DB_WITH_FILE = "RESET_SANCTIONS_DB_WITH_FILE",
  AUTH = "AUTH",
  REGISTER = "REGISTER",
  VERIFY = "VERIFY",
  SUBMIT_CONTACT_FORM = "SUBMIT_CONTACT_FORM",
  RECOVER_PASSWORD_REQUEST = "RECOVER_PASSWORD_REQUEST",
  RECOVER_PASSWORD_CONFIRM = "RECOVER_PASSWORD_CONFIRM",
  REGISTRATION_CONFIRM = "REGISTRATION_CONFIRM",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  CHANGE_PROFILE = "CHANGE_PROFILE",
}
