import { ClientCategory, Tarrif } from "shared/billing";

export const TARRIFS_PRIVATE: Array<Tarrif> = [
  {
    durationTitle: "1 месяц",
    description: "Подойдет, если вы не ведете проверки на постоянной основе",
    features: [
      "Полный доступ ко всем спискам",
      "Поддержка 24/7",
      "300 запросов в месяц",
      "3 устройства для доступа",
    ],
    price: 5000,
    category: ClientCategory.private,
  },
  {
    durationTitle: "6 месяцев",
    description:
      "Оптимальный вариант, если хотите с нами работать, но не уверены как часто будете использовать проверки",
    features: [
      "Полный доступ ко всем спискам",
      "Поддержка 24/7",
      "Неограниченное число запросов",
      "3 устройства для доступа",
      "Экономия 17%",
    ],
    price: 25000,
    category: ClientCategory.private,
  },
  {
    durationTitle: "12 месяцев",
    description:
      "Подойдет для пользователей, готовых вести долгосрочное сотрудничество",
    features: [
      "Полный доступ ко всем спискам",
      "Поддержка 24/7",
      "Неограниченное число запросов",
      "3 устройства для доступа",
      "Экономия 25%",
    ],
    price: 45000,
    category: ClientCategory.private,
  },
];

export const TARRIFS_COMPANY: Array<Tarrif> = [
  {
    durationTitle: "1 месяц",
    description: "Подойдет, если вы не ведете проверки на постоянной основе",
    features: [
      "Полный доступ ко всем спискам",
      "Поддержка 24/7",
      "1000 запросов в месяц",
      "Неограниченное число устройств для доступа",
    ],
    price: 15000,
    category: ClientCategory.company,
  },
  {
    durationTitle: "6 месяцев",
    description:
      "Оптимальный вариант, если хотите с нами работать, но не уверены как часто будете использовать проверки",
    features: [
      "Полный доступ ко всем спискам",
      "Поддержка 24/7",
      "Неограниченное число запросов",
      "Неограниченное число устройств для доступа",
      "Экономия 17%",
    ],
    price: 75000,
    category: ClientCategory.company,
  },
  {
    durationTitle: "12 месяцев",
    description:
      "Подойдет для пользователей, готовых вести долгосрочное сотрудничество",
    features: [
      "Полный доступ ко всем спискам",
      "Поддержка 24/7",
      "Неограниченное число запросов",
      "Неограниченное число устройств для доступа",
      "Экономия 25%",
    ],
    price: 135000,
    category: ClientCategory.company,
  },
];
